/* ==========================================================================
  Header
========================================================================== */

.site-header {
  background: $cream;
  border-bottom: 1px solid $lightgreen;
  color: #fff;
  .wrap {
    position: relative;
    padding: 20px;
    max-width: 1180px;
    margin: 0 auto;
    @include bp($medium) {
      display: flex;
      justify-content: space-between;
    }
    @include bp($large) {
      align-items: center;
      padding: 15px 20px;
    }
  }
}

.site-logo {
  margin: 0 0 30px;
  height: 100%;
  display: inline-block;
  @include bp($medium) {
    margin: 0;
  }
  a {
    display: block;
    text-indent: -9999px;
    background: url('../img/farm-credit-express-logo.png') no-repeat top;
    background-size: contain;
    width: 280px;
    height: 97px;
    color: $black;
    transition: opacity 0.3s ease;
    @include bp($medium) {
      width: 200px;
      height: 70px;
    }
    @include bp($large) {
      width: 300px;
      height: 80px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

.navbar-toggle {
  position: absolute;
  top: 20px;
  right: 15px;
  padding: 9px 10px;
  background-color: $blue;
  background-image: none;
  border: 2px solid $lightgreen;
  border-radius: 4px;
  cursor: pointer;
  .icon-bar {
    background-color: $white;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    +.icon-bar {
      margin-top: 4px;
    }
  }
  @include bp($medium) {
    display: none;
  }
}

.navbar-toplinks {
  margin: 10px auto;
  @include bp($medium) {
    display: flex;
    justify-content: flex-end;
  }
  .button {
    display: block;
    margin-bottom: 15px;
    @include bp($medium) {
      margin-right: 15px;
    }
  }
}