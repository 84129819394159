// Global variables and mixins
@import "modules/variables";
@import "modules/mixins";

//Vendor
@import "partials/vendor/normalize";
@import "partials/vendor/base";
@import "partials/vendor/datatables.scss";

// Partials
@import "partials/typography";
@import "partials/buttons";
@import "partials/layout";
@import "partials/header";
@import "partials/footer";
@import "partials/navigation";
@import "partials/layer-hero";
@import "partials/layer-about";
@import "partials/layer-contact";
@import "partials/layer-calculator";
@import "partials/layer-dealers";

// Print
@media print {
	@import "partials/print";
}
