/* ==========================================================================
  Layout
========================================================================== */
.basic-page {
  background: $white;
  p,
  ul {
    font-size: 1.125rem;
  }
}
.container {
  max-width: 1180px;
  margin: 0 auto;
  padding: 60px 20px;

  .wrapper {
    max-width: 975px;
    margin: 0 auto;
  }
}
.layer {
  .wrap {
    max-width: 750px;
    margin: 0 auto;
    padding: 60px 20px;
    @include bp($large) {
      max-width: 1000px;
    }
    @include bp($xlarge) {
      max-width: 1170px;
    }
  }
  &.white {
    background: $white;
  }
  &.blue {
    background: $blue;
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: $lightgreen;
    }
    a {
      &:hover {
        color: $white;
      }
    }
  }
  .callout {
    max-width: 640px;
    margin: 0 auto 40px;
    text-align: center;
    .button {
      max-width: 400px;
      font-size: 1.125rem;
      margin: 0 auto;
    }
  }
}

.dealer-table {
  min-height: 600px;
  overflow-y: auto;
}

#ajax-loader {
  display: none; /* Initially hidden */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}