/* ==========================================================================
  Contact Layer 
========================================================================== */

.layer__contact {
  position: relative;
  .callout {
    max-width: 640px;
    margin: 0 auto 40px;
    text-align: center;
    p {
      color: $white;
      font-size: 1.25rem;
    }
    &.secondary {
      p {
        font-size: 1rem;
      }
      a {
        text-decoration: underline;
        color: #66A636;
      }
    }
  }
  .form {
    margin: 0 auto 40px;
    @include bp($xlarge) {
      max-width: 83%;
    }
  }
}