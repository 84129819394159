/* ==========================================================================
  calculator layer
========================================================================== */

.layer__calculator {
  position: relative;
  .callout {
    p {
      color: $white;
      font-size: 1.25rem;
    }
  }
}
.calculators__container {
  margin-bottom: 1.5rem;
  @include bp($large) {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  &.single {
    @include bp($large) {
      justify-content: center;
    }
  }
}
.calculator {
  text-align: center;
  &__loan {
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    @include bp($large) {
      width: 42%;
    }
  }
  &__lease {
    @include bp($large) {
      width: 55%;
    }
  }
  #tvcPC09 {
    background: #fafafa;
    border-radius: 3px;
    font-family: $futura;
    box-shadow: 0 0 6px rgba(0,0,0,.4);
    padding: 20px 0 0;
    div {
      padding: 0 24px;
    }
  }
  .Title h2 {
    color: $darkgreen;
    font-size: 1.5rem;
    margin: 0 0 20px;
  }
  .Description p {
    font-size: 1.125rem
  }
  .ErrorSummary {
    font-weight: bold;
    color: Red;
  }
  .InputTable,
  .DetailTable {
    border: none;
    border-collapse: collapse;
    td {
      padding: 0 0 .6em;
      max-width: 160px;
      text-align: left;
      @include bp($medium) {
        max-width: auto;
      }
    }
    p {
      padding-bottom: 0 !important;
    }
    input {
      font-family: $futura;
      border: 1px solid #888;
      padding: 4px;
      box-shadow: inset 0 0 5px rgba(0,0,0,.2);
    }
    select {
      font-family: $futura;
    }
  }
  .TextInput {
    width: 6em;
  }
  .InputContainer p {
    font-size: 1.125rem;
  }
  #txtLOANAMOUNT, 
  #txtINTERESTRATE, 
  #txtTERM, 
  #txtCOMPOUNDING {
    font-weight: bold;
    min-width: 200px;
  }
  #tvcTERM_UNITS_Months,
  #tvcTERM_UNITS_Years {
    margin-left: .35em;
    margin-right: .2em;
    display: inline-block;
  }
  .tvcRadioLabelClass {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 1.125rem;
  }
  #COMPUTE {
    font-family: $futura;
    font-weight: 700 !important;
    border-radius: 4px !important;
    color: $white !important;
    padding: 14px 16px !important;
    letter-spacing: 1px !important;
    text-align: center !important;
    font-size: 1.125rem !important;
    text-transform: uppercase;
    border: 0 !important;
    background: $darkgreen !important;
    text-shadow: none !important;
    width: 100% !important;
    height: auto !important;
    cursor: pointer;
    transition: background 0.3s ease;
    &:hover {
      background-color: lighten($green, 10) !important;
    }
  }
  #Panel_Results {
    background-color: $green;
    border-top: 1px solid #47781f;
    padding: 0!important;
    border-radius: 0 0 3px 3px;
    #tvcCalculatorResultsId {
      display: none;
    }
  }
  .Summary {
    color: $white !important;
    font-size: 1.5em;
    margin: 0 auto;
    padding: 20px 20px 0 !important;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0,0,0,.4);
  }
  .DetailContainer {
    padding: .75em !important;
    overflow: scroll;
    @include bp($medium) {
      overflow: unset;
    }
  }
  .calculate {
    cursor: pointer;
  }
  #A_CalculatorDisclaimer {
    color: $white;
    display: block;
    font-size: 0.875rem !important;
    margin: 1em 0 -20px !important;
  }
  #calculator>p:nth-child(7)>a {
    font-size: 16px;
    color: #c3daae;
  }
  table {
    width: auto;
    margin: 0 0 1rem;
  }
  td {
    padding: 0;
    border: 0;
  }
  fieldset {
    padding: 10px;
    margin: 0;
    border: 0;
    min-width: 0;
    text-align: left;
  }
  legend {
    display: block;
    width: 100%;
    line-height: inherit;
    border-bottom: 1px solid #e5e5e5;
  }
  .cAmortTitleFont {
    color: #fff;
    margin-top: 20px;
    font-size: 1.125rem;
    font-weight: 700;
  }
  .cTableWrapper {
    border: none;
    border-collapse: collapse;
  }
  .cAmortContainer .cTableWrapper {
    background: $white;
    border: 1px solid #dedff0;
    font-size: .8em !important;
  }
}