/* ==========================================================================
	Variables
========================================================================== */

// Fonts
$pt-sans:  "pt-sans","Helvetica Neue",Helvetica,Arial;
$futura: "futura-pt","PT Sans","Helvetica Neue",Helvetica,Arial;

$base-font-size: 16px;

// Media Query Breakpoints
$xsmall:      20em;      // 320px
$small:       30em;      // 480px
$medium:      48em;      // 768px
$large:       64.0625em; // 1025px (because iPad)
$xlarge:      80em;      // 1280px

// Colors
$cream:        #efe8ba;
$cream-alt:    #f0e9b4;
$lightgreen:   #c3daae;
$green:        #5e9731; // not accessible at small sizes
$darkgreen:    #4E8028;
$blue:         #002d57;
$lightblue:    #23527c;
$lightgray:    #e7e7e7;
$black:        #000000;
$white:        #ffffff;