/* ==========================================================================
  Buttons
========================================================================== */

.button {
  font-family: $futura;
  border-radius: 4px;
  color: $white;
  padding: 12px 16px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease;
  &-small {
    padding: 8px 12px;
    font-size: 15px;
  }
  &-block {
    display: block;
    margin-bottom: 16px;
    text-align: center;
  }
  &-blue {
    background-color: $blue;
    &:hover {
      background-color: lighten($blue, 10);
      color: $white;
      text-decoration: none;
    }
  }
  &-green {
    background-color: $darkgreen;
    &:hover {
      background-color: lighten($darkgreen, 10);
      color: $white;
      text-decoration: none;
    }
  }
}