/* ==========================================================================
  Hero layer
========================================================================== */

.layer__hero {
  background: $black url('../img/hero.jpg') no-repeat;
  background-size: cover;
  min-height: 600px;
  .wrap {
    padding: 75px 20px;
    @include bp($medium) {
      padding: 120px 20px;
    }
    @include bp($large) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
    }
    @include bp($xlarge) {
      max-width: 1200px;
    }
  }
  .hero-heading {
    text-align: center;
    margin-bottom: 40px;
    @include bp($large) {
      padding-top: 40px;
      text-align: right;
      margin: 0 15px;
      width: 66.6%;
    }
  }
  h1 {
    font-size: 3rem;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    @include bp($medium) {
      font-size: 3.75rem;
    }
  }
  h2 {
    font-size: 2rem;
    font-weight: 400;
    color: $lightgreen;
    text-transform: uppercase;
    margin-bottom: 40px;
    @include bp($medium) {
      font-size: 2.5rem;
    }
  }
  .buttons {
    @include bp($large) {
      display: flex;
      justify-content: space-between;
    }
  }
  .button {
    font-size: 1.125rem;
    @include bp($large) {
      width: 50%;
    }
    &:nth-of-type(2) {
      @include bp($large) {
        margin-left: 30px;
      }
    }
    &:hover {
      background: $blue;
    }
  }
  .hero-box {
    background: rgba($white, 0.7);
    @include bp($large) {
      margin: 0 15px;
      width: 33.3%;
    }
    &__heading {
      background: $blue;
      padding: 15px 20px;
      h4 {
        text-transform: uppercase;
        font-size: 1.25rem;
        color: #69A937;
      }
      .heading-alt {
        font-weight: 400;
        color: $white;
      }
    }
    &__body {
      padding: 20px;
      p {
        margin-bottom: 10px;
        line-height: 1.4;
      }
    }
  }
  &-dealer {
    .hero-heading {
      text-align: center;
      margin: 0 auto;
    }
    .button {
      @include bp($large) {
        width: 66%;
        margin: 0 auto;
      }
    }
  }
}