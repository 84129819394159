/* ==========================================================================
	Typography
========================================================================== */

html {
	color: $black;
	background-color: transparent;
}

body { 
	font-family: $pt-sans;
	position: relative;
	font-size: 16px;
	line-height: 1.5;
}

h1,
.h1 {
	font-family: $futura;
	font-weight: 600;
	font-size: 2.25rem;
	line-height: 1.1;
	margin: 20px auto 10px;
	color: $darkgreen;
}

h2,
.h2 {
	font-family: $futura;
	font-weight: 600;
	font-size: 1.875rem;
	line-height: 1.1;
	margin: 20px auto 10px;
	color: $darkgreen;
}

h3,
.h3 {
	font-family: $futura;
	font-weight: 300;
	font-size: 1.875rem;
	line-height: 1.1;
	margin: 20px auto 10px;
	color: $darkgreen;
}

h4,
.h4 {
	font-family: $futura;
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 1.1;
	margin: 10px auto;
	color: $darkgreen;
}

h5,
.h5 {
	font-family: $futura;
	font-weight: 600;
	font-size: 0.875rem;
	margin: 10px auto;
	color: $darkgreen;
}

h6,
.h6 {
	font-family: $futura;
	font-weight: 600;
	font-size: 0.75rem;
	margin: 10px auto;
	color: $darkgreen;
}

p {
	margin: 0 auto 24px;
	&.disclaimer {
    font-size: 0.75rem;
  }
}

a {
	color: $darkgreen;
	background-color: transparent;
	text-decoration: none;
	transition: color 0.2s ease;

	&:hover {
		text-decoration: underline;
		color: $lightblue;
	}
}

ul, ol {
	margin: 0 0 24px;

	ul, ol {
		margin: 0;
	}
}

blockquote {
	font-size: 1.125rem;
	font-family: $futura;
	padding: 24px;
	margin: 0 auto 24px;
}

table {
	width: 100%;
	margin: 0 auto 24px;
}

tbody {
	border: none;
}

th {
	text-align: left;
	text-transform: uppercase;
	padding: 0 1em;
	border: none;
}

td {
	padding: 10px 1em 11px;
	border-bottom: 1px solid #fff;
}

.swipeAction {
	background: #333;
	color: #fff;
	line-height: 40px;
	padding: 0 10px;
	position: relative;
	text-align: center;
}
 
.tableWrapper {
	overflow: scroll;
}

img {
	margin-bottom: 24px;
}

figure {
	img {
		margin-bottom: 14px;
	}

	figcaption {
		font-style: italic;
		text-align: center;
	}
}

.intro {
	font-size: 1.125rem;
}

.center {
	text-align: center;
}