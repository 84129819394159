/* ==========================================================================
	Mixins
========================================================================== */

// Media Queries
//   Usage:
//   @include breakpoint($large) { };
//   @include breakpoint(400px, min-height) { };
$media-feature: 'min-width' !default;
@mixin breakpoint($point, $feature: $media-feature) {
    @media (#{$feature}: $point) {
        @content;
    }
}
@mixin bp($point, $feature: $media-feature) {
    @media (#{$feature}: $point) {
        @content;
    }
}

// px to rem
@function rem($px) {
  @return ($px/$base-font-size)+rem;
}

@function strip-units($value) {
  @return $value / ($value * 0 + 1);
}

@function fluid($size, $unit: vw) {
  $size: strip-units($size);
  $fluid-value: ($size / 1440 * 100) + $unit;
  @return $fluid-value;
}

// Contain Floats
@mixin clearfix {
	&:after { content: ""; display: table; clear: both; }
}

// Placeholder styling
@mixin placeholder {
    $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
    &.placeholder {
        @content;
    }
}

// Font Smoothing - Mac OS X only
@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// triangle mixin http://codepen.io/iMarkup/pen/wBqjVO
@mixin triangle ($size, $color, $direction) {
  height: 0;
  width: 0;

  $width: nth($size, 1);
  $height: nth($size, length($size));

  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {

    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;

    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;

    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;

    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  }

  @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;

    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;

    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  }

  @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  }

  @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  }

  @else if ($direction == inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  }

  @else if ($direction == inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}
