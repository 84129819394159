/* ==========================================================================
  Navigation
========================================================================== */
.nav-container {
  position: relative;
}
.main-navigation {
  display: none;
  border-top: 1px solid $lightgray;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
  padding: 0 20px;
  margin: 0 -20px;
  @include bp($medium) {
    display: block !important;
    box-shadow: none;
    border: 0;
    margin: 0;
  }
  .nav {
    list-style-type: none;
    margin: 7.5px -20px;
    padding: 0;
    @include bp($medium) {
      display: flex;
    }
    >li {
      position: relative;
      > a {
        display: block;
        position: relative;
        color: $black;
        font-family: $futura;
        font-size: 17px;
        line-height: 20px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 10px 15px;
        &:hover {
          background-color: $blue;
          border-radius: 4px;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}