/* ==========================================================================
  Dealers layer 
========================================================================== */

.layer__dealers {
  position: relative;
  .callout {
    p {
      font-size: 1.25rem;
      &.disclaimer {
        font-size: 0.938rem;
      }
      a {
        text-transform: uppercase;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  } 
  .dealer-table {
    margin: 40px 0;
    .top {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
    }
    .bottom {
      font-weight: 600;
    }
    .dataTables_wrapper .dataTables_filter {
      margin: 0;
    }
  }
  .dataTable {
    margin: 20px 0;
    thead {
      border: 1px solid #d3d3d3;
      color: #555;
    }
    td {
      padding: 3px 6px;
    }
    tbody {
      tr:nth-of-type(odd) {
        background-color: #eee;
      }
    }
  }
}