/* ==========================================================================
  About Layer 
========================================================================== */

.layer__about {
  &.white {
    background: $white;
  }
  .content {
    font-size: 1.125rem;
    @include bp($medium) {
      width: 66.6%;
      padding: 0 15px;
      margin: 0 auto;
    }
    h3 {
      margin: 20px 0;
    }
  }
  .no-futura {
    font-family: $pt-sans;
  }
  .left {
    margin-bottom: 40px;
    @include bp($large) {
      width: 42%;
    }
  }
  .right {
    @include bp($large) {
      width: 50%;
    }
  }
  .box {
    background: $cream-alt;
    border: 2px solid $lightgreen;
    box-shadow: 1px 1px 3px 0 rgba(50,50,50,.75);
    padding: 20px 30px;
    margin-bottom: 20px;
    position: relative;
    h2 {
      font-size: 1.75rem;
      font-weight: 400;
      color: $black;
      line-height: 1.5;
      margin: 10px 0;
    }
  }
  .columns {
    @include bp($large) {
      display: flex;
      justify-content: space-between;
    }
    .content {
      width: 100%;
      padding: 0;
    }
  }
}