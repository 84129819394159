/* ==========================================================================
  Site footer
========================================================================== */

.site-footer {
  background: $cream;
  border-bottom: 1px solid $lightgreen;
  .wrap {
    max-width: 1180px;
    margin: 0 auto;
    position: relative;
    padding: 40px 20px;
    @include bp($medium) {
      display: flex;
      justify-content: space-between;
    }
  }
}
.footer-logo {
  margin: 0 auto 30px;
  height: 100%;
  @include bp($medium) {
    margin: 0;
  }
  a {
    display: block;
    margin: 0 auto;
    color: $black;
    text-indent: -9999px;
    background: url('../img/farm-credit-express-logo.png') no-repeat top;
    background-size: contain;
    width: 250px;
    height: 67px;
    transition: opacity 0.3s ease;
    @include bp($medium) {
      width: 200px;
      height: 70px;
    }
    @include bp($large) {
      width: 300px;
      height: 80px;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}
.footer-nav {
  .nav {
    list-style-type: none;
    margin: 0 0 15px;
    padding: 0;
    text-align: center;
    @include bp($medium) {
      display: flex;
    }
    >li {
      position: relative;
      > a {
        display: block;
        position: relative;
        color: $black;
        font-family: $futura;
        font-size: 17px;
        line-height: 20px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 10px 15px;
        &:hover {
          background-color: $blue;
          border-radius: 4px;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
  .legal {
    font-size: 0.875rem;
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    @include bp($medium) {
      padding: 0 10px 0 0;
      justify-content: flex-end;
    }
    li {
      margin-right: 5px;
    }
    a {
      margin-right: 3px;
      color: #457023;
    }
  }
}